<template>
  <div class="product-tags">
    <e-select
      :value="products"
      style="width: 350px"
      :options="found_products"
      multiple
      api_search
      only_search
      hide_bin
      :clear_on_select="false"
      :loading="$apollo.queries.ProductSearch.loading"
      :dropdown_width="500"
      @input="setProducts"
      @search="changeMarginTableSearch"
    />
    <div
      v-for="prod of products"
      :key="prod.id"
      class="product-tag"
    >
      <div class="text">{{ prod.name }}</div>
      <div
        class="close-tag"
        @click="remove_tag(prod)"
      >
        <img
          src="/img/icons/close-tag.svg"
          alt="close-tag"
        />
      </div>
    </div>
    <div
      v-if="products.length"
      class="clear-tags"
      @click="clear_tags()"
    >
      Сбросить
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'AnalyticsProductSearch',
    props: {
      products: Array
    },
    apollo: {
      ProductSearch: {
        query: require('../gql/ProductSearch.gql'),
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables() {
          return {
            input: {
              name: this.searchText,
              branch: this.currentBranch.id
            }
          }
        },
        result({ data }) {
          this.found_products = data?.ProductSearch?.products || []
        }
      }
    },

    data() {
      return {
        searchText: '',
        found_products: [],
        debounce: null
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },
    methods: {
      clear_tags() {
        this.$emit('input', [])
      },
      remove_tag(product) {
        this.$emit(
          'input',
          this.products.filter((el) => el.id !== product.id)
        )
      },
      setProducts(value) {
        this.$emit('input', value)
      },
      changeMarginTableSearch(value) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.searchText = value
        }, 300)
      }
    }
  }
</script>

<style scoped lang="scss">
  .product-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .product-tag {
      border-radius: 3px;
      background: var(--blue-100, #ddf3ff);
      color: var(--text-system-blue-400, #00a3ff);
      padding: 6px 12px 6px 12px;
      display: flex;
      align-items: center;
      .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
      }
      .close-tag {
        background: #bbe6ff;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 90px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
    .clear-tags {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-secondary-gray-400, #888);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
</style>
