var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-tags"
  }, [_c('e-select', {
    staticStyle: {
      "width": "350px"
    },
    attrs: {
      "value": _vm.products,
      "options": _vm.found_products,
      "multiple": "",
      "api_search": "",
      "only_search": "",
      "hide_bin": "",
      "clear_on_select": false,
      "loading": _vm.$apollo.queries.ProductSearch.loading,
      "dropdown_width": 500
    },
    on: {
      "input": _vm.setProducts,
      "search": _vm.changeMarginTableSearch
    }
  }), _vm._l(_vm.products, function (prod) {
    return _c('div', {
      key: prod.id,
      staticClass: "product-tag"
    }, [_c('div', {
      staticClass: "text"
    }, [_vm._v(_vm._s(prod.name))]), _c('div', {
      staticClass: "close-tag",
      on: {
        "click": function ($event) {
          return _vm.remove_tag(prod);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/img/icons/close-tag.svg",
        "alt": "close-tag"
      }
    })])]);
  }), _vm.products.length ? _c('div', {
    staticClass: "clear-tags",
    on: {
      "click": function ($event) {
        return _vm.clear_tags();
      }
    }
  }, [_vm._v(" Сбросить ")]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }